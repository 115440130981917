import { useState } from "react";
import RecordButton from "./components/RecordButton";
import DiscardButton from "./components/DiscardButton";
import SendButton from "./components/SendButton";
import AudioWaves from "./components/AudioWaves";
import { useSearchParams } from "react-router-dom";
import PauseButton from "./components/PauseButon";

interface frameCommunicationSendData {
  uniqueLoadId?: string;
  action?: string;
  message?: string;
}

function AudioRecorder() {
  const [recorder, setRecorder] = useState<MediaRecorder | null>(null);
  const [recordingStatus, setRecordingStatus] = useState("inactive");
  const [audio, setAudio] = useState<Blob | null>(null);
  const [audioUrl, setAudioUrl] = useState("");
  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null);
  const [sending, setSending] = useState(false);
  const [errorSending, setErrorSending] = useState(false);
  const [fileTooBig, setFileTooBig] = useState(false);
  const [searchParams] = useSearchParams();
  const language = searchParams.get("LANG") ?? "en";

  function frameCommunicationInit() {
    if (!window.frameCommunication) {
      window.frameCommunication = { timeout: {} };
    }

    if (typeof window.postMessage === "function") {
      window.addEventListener("message", function (event) {
        try {
          const data = JSON.parse(event.data);

          if (data.action == "init") {
            window.frameCommunication.uniqueLoadId = data.uniqueLoadId;
            window.frameCommunication.postMessageSource = event.source;
            window.frameCommunication.postMessageOrigin =
              event.origin as WindowPostMessageOptions;
          }
        } catch (e) {
          return false;
        }
      });
    }
  }

  function frameCommunicationSend(data: frameCommunicationSendData) {
    data["uniqueLoadId"] = window.frameCommunication.uniqueLoadId;

    const encodedData = JSON.stringify(data);

    if (typeof window.postMessage === "function") {
      if (window.frameCommunication.postMessageSource) {
        window.frameCommunication.postMessageSource.postMessage(
          encodedData,
          window.frameCommunication.postMessageOrigin
        );
      }
    }
  }

  frameCommunicationInit();

  if (errorSending) {
    return (
      <div className="h-40 flex justify-center items-center bg-white space-x-4 text-red-600">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-20 h-20"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
          />
        </svg>
        <div className="text-red-600 font-sans text-lg font-bold mb-3">
          {fileTooBig
            ? language === "br"
              ? "O tamanho do arquivo é maior que o suportado"
              : language === "la"
              ? "El tamaño del archivo es mayor que el admitido"
              : "File size is too big"
            : language === "br"
            ? "Erro ao enviar, por favor contate o suporte"
            : language === "la"
            ? "Error al enviar, por favor contacte con soporte"
            : "Error sending file, please contact our support"}
        </div>
      </div>
    );
  }

  if (sending) {
    return (
      <div className="h-40 flex justify-center items-center bg-white space-x-4">
        <div className="text-primary-pure font-sans text-4xl font-bold mb-3">
          {language === "br" || language === "la" ? "Enviando" : "Sending"}
        </div>
        <div className="w-4 h-4 rounded-full animate-pulse bg-primary-pure"></div>
        <div className="w-4 h-4 rounded-full animate-pulse bg-primary-pure"></div>
        <div className="w-4 h-4 rounded-full animate-pulse bg-primary-pure"></div>
      </div>
    );
  }

  return (
    <div className="h-[150px] flex justify-center items-center bg-white ">
      {sending}
      {audio || recordingStatus === "recording" ? (
        <DiscardButton
          setAudio={setAudio}
          setAudioUrl={setAudioUrl}
          setMediaStream={setMediaStream}
          setRecordingStatus={setRecordingStatus}
          recorder={recorder}
        />
      ) : null}
      {recordingStatus === "recording" ? (
        <>
          <div className="mx-1 animate-record-pulse bg-red-600 block w-3 h-3 rounded-full"></div>
          <AudioWaves mediaStream={mediaStream} />
        </>
      ) : null}
      {audio && recordingStatus !== "recording" ? (
        <audio
          src={audioUrl}
          controls
          className="block w-[200px] mx-1"
          title="audio.mp3"
        ></audio>
      ) : null}
      {recordingStatus === "inactive" ? (
        <RecordButton
          setMediaStream={setMediaStream}
          setRecorder={setRecorder}
          recordingStatus={recordingStatus}
          setRecordingStatus={setRecordingStatus}
        />
      ) : null}
      {recordingStatus === "recording" ? (
        <PauseButton
          recorder={recorder}
          setAudio={setAudio}
          setAudioUrl={setAudioUrl}
          setRecordingStatus={setRecordingStatus}
        />
      ) : null}

      {audio ? (
        <SendButton
          audio={audio}
          frameCommunicationSend={frameCommunicationSend}
          setSending={setSending}
          setErrorSending={setErrorSending}
          setFileTooBig={setFileTooBig}
        />
      ) : null}
    </div>
  );
}

export default AudioRecorder;
