import { useEffect } from "react";

interface AudioWaveParams {
  mediaStream: MediaStream | null;
}

function AudioWaves({ mediaStream }: AudioWaveParams) {
  useEffect(() => {
    if (!mediaStream) {
      return;
    }

    const stream = mediaStream;

    const audioCtx = new AudioContext();
    const analyser = audioCtx.createAnalyser();
    const source = audioCtx.createMediaStreamSource(stream);
    source.connect(analyser);
    analyser.fftSize = 256;
    const bufferLength = analyser.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);
    const canvas = document.getElementById("teste") as HTMLCanvasElement;
    const width = canvas.width;
    const height = canvas.height;
    const canvasCtx = canvas.getContext("2d");

    if (canvasCtx) {
      canvasCtx.clearRect(0, 0, width, height);
    }

    const draw = () => {
      requestAnimationFrame(draw);

      if (canvasCtx) {
        analyser.getByteTimeDomainData(dataArray);
        canvasCtx.fillStyle = "rgb(256, 256, 256)";
        canvasCtx.fillRect(0, 0, width, height);

        const barWidth = (width / bufferLength) * 2.5;
        let x = 0;

        for (let i = 0; i < bufferLength; i++) {
          const audioHeight = dataArray[i];

          let barHeight = height - (audioHeight - 100);
          barHeight = barHeight < 40 ? barHeight : height;
          canvasCtx.fillStyle = `rgb(8, 170, 242)`;
          // canvasCtx.fillRect(x, height - barHeight / 2, barWidth, height);

          canvasCtx.fillRect(x, barHeight, barWidth, height);

          x += barWidth + 1;
        }
      }
    };
    draw();
  }, [mediaStream]);

  return (
    <div className="mx-1">
      <canvas id="teste" width={160} height={40}></canvas>
    </div>
  );
}

export default AudioWaves;
