import AudioRecorder from "./AudioRecorder";
import { BrowserRouter, Route, Routes } from "react-router-dom";

const App = () => {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<AudioRecorder />}></Route>
      </Routes>
    </BrowserRouter>
  );
};
export default App;
