interface RecordButtonParams {
  setMediaStream: React.Dispatch<React.SetStateAction<MediaStream | null>>;
  setRecorder: React.Dispatch<React.SetStateAction<MediaRecorder | null>>;
  recordingStatus: string;
  setRecordingStatus: React.Dispatch<React.SetStateAction<string>>;
}

function RecordButton({
  setMediaStream,
  setRecorder,
  recordingStatus,
  setRecordingStatus,
}: RecordButtonParams) {

  const startRecording = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    setMediaStream(stream);

    const mediaRecorder = new MediaRecorder(stream);
    mediaRecorder.start();

    setRecorder(mediaRecorder);
    setRecordingStatus("recording");
  };

  return (
    <div>
      {recordingStatus === "inactive" ? (
        <button
          onClick={startRecording}
          className="animate-pulse bg-primary-pure rounded-full p-2 text-white"
          type="button"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-16 h-16"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 18.75a6 6 0 006-6v-1.5m-6 7.5a6 6 0 01-6-6v-1.5m6 7.5v3.75m-3.75 0h7.5M12 15.75a3 3 0 01-3-3V4.5a3 3 0 116 0v8.25a3 3 0 01-3 3z"
            />
          </svg>
        </button>
      ) : (
        <button
          onClick={startRecording}
          className="bg-primary-pure rounded-full p-2 text-white mx-1"
          type="button"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 18.75a6 6 0 006-6v-1.5m-6 7.5a6 6 0 01-6-6v-1.5m6 7.5v3.75m-3.75 0h7.5M12 15.75a3 3 0 01-3-3V4.5a3 3 0 116 0v8.25a3 3 0 01-3 3z"
            />
          </svg>
        </button>
      )}
    </div>
  );
}

export default RecordButton;
