interface PauseButtonParams {
  recorder: MediaRecorder | null;
  setAudio: React.Dispatch<React.SetStateAction<Blob | null>>;
  setAudioUrl: React.Dispatch<React.SetStateAction<string>>;
  setRecordingStatus: React.Dispatch<React.SetStateAction<string>>;
}

function PauseButton({
  recorder,
  setAudio,
  setAudioUrl,
  setRecordingStatus,
}: PauseButtonParams) {
  const stopRecording = async () => {
    if (recorder) {
      recorder.ondataavailable = (event) => {
        if (event.data) {
          const audioUrl = URL.createObjectURL(event.data);
          setAudio(event.data);
          setAudioUrl(audioUrl);
          setRecordingStatus("paused");
        }
      }

      recorder.stop();
    }
  };

  return (
    <div>
      <button
        onClick={stopRecording}
        className="bg-primary-pure rounded-full p-2 text-white mx-1"
        type="button"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15.75 5.25v13.5m-7.5-13.5v13.5"
          />
        </svg>
      </button>
    </div>
  );
}

export default PauseButton;
