import React from "react";
import { useSearchParams } from "react-router-dom";

interface SendButtonParams {
  audio: Blob;
  frameCommunicationSend: (arg: object) => void;
  setSending: React.Dispatch<React.SetStateAction<boolean>>;
  setErrorSending: React.Dispatch<React.SetStateAction<boolean>>;
  setFileTooBig: React.Dispatch<React.SetStateAction<boolean>>;
}

function SendButton({
  audio,
  frameCommunicationSend,
  setSending,
  setErrorSending,
  setFileTooBig,
}: SendButtonParams) {

  const [searchParams] = useSearchParams();

  const sendAudioFile = async () => {
    setSending(true);
    const data = new FormData();
    const domain = searchParams.get("DOMAIN") ?? "";
    const dialogId = searchParams.get("DIALOG_ID") ?? "";
    const dialogEntityId = searchParams.get("DIALOG_ENTITY_ID") ?? "";
    const language = searchParams.get("LANG") ?? "en";

    data.append("audio", audio);
    data.append("domain", domain);
    data.append("dialogId", dialogId);
    data.append("dialogEntityId", dialogEntityId);

    if (audio.size > 104857600) {
      setErrorSending(true);
      setFileTooBig(true);
      return;
    }

    const url = process.env.REACT_APP_WHATSAPP_BACKEND_URL;

    try {
      const result = await fetch(`${url}/get_audio_url`, {
        method: "POST",
        body: data,
      });

      if (result.status === 200) {
        const resultBody = await result.json();
        const fileLink = resultBody.url;
        const message =
          language === "br"
            ? `[url=${fileLink}]🎵 PowerZap Áudio 🎵[/url]`
            : `[url=${fileLink}]🎵 PowerZap Audio 🎵[/url]`;

        frameCommunicationSend({
          action: "send",
          message,
        });
        frameCommunicationSend({ action: "close" });
      } else {
        setErrorSending(true);
      }
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  return (
    <div>
      <button
        className="bg-primary-pure rounded-full p-2 text-white mx-1"
        onClick={sendAudioFile}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5"
          />
        </svg>
      </button>
    </div>
  );
}

export default SendButton;
